import React, { Suspense, lazy } from "react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme from "./theme";

const Home = lazy(() => import("./pages/Home"));
const Result = lazy(() => import("./pages/Result"));

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense
          fallback={
            <div>
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/result/:city" element={<Result />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default App;
