import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fd7b2b",
      light: "#ffaf75",
      dark: "#c55a1f",
    },
    secondary: {
      main: "#424242",
    },
    background: {
      default: "#f9f9f9",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
    },
  },
  typography: {
    fontFamily: '"Instrument Sans", "Instrument Serif", Arial, sans-serif',
    h1: {
      fontWeight: 700,
      letterSpacing: "0.05em",
      fontSize: "2.5rem",
      "@media (min-width:600px)": {
        fontSize: "3.5rem",
      },
      "@media (min-width:960px)": {
        fontSize: "4.5rem",
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: "2rem",
      "@media (min-width:600px)": {
        fontSize: "2.5rem",
      },
      "@media (min-width:960px)": {
        fontSize: "3rem",
      },
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.75rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
    },
    body1: {
      fontSize: "1rem",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
          fontWeight: "bold",
          padding: "8px 16px",
          transition: "transform 0.3s ease, background 0.3s ease",
          "&:hover": {
            transform: "scale(1.03)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
  },
});

export default theme;
